import React, { useState, useEffect } from 'react'
import { Table, Tag, Modal, Select, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import accessRep from '../repository/accessRep';
import rulesRep from '../repository/rulesRep';
import { sendNotify } from '../helper/notification';

import { getAccess, getCategory } from '../store/uploads/accessSlice';

export default function Assign() {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState([])
  const [catOptions, setCatOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState('')
  const [clientName, setClientName] = useState('')
  const [ruleOptions, setRuleOptions] = useState([]);
  const [period, setPeriod] = useState(null);
  const [rules, setRules] = useState();
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);

  const access = useSelector((state) => state.access)

  useEffect(() => {
    getThresholds();
    getRulesList();
  }, []);

  const getRulesList = async () => {
    rulesRep.getRulesList().then(response => {
      if (response.error) {
        sendNotify('error', response.error?.response?.data?.message)
      } else {
        let temp = [];
        if (response && response.data) {
          (response.data).map(res => {
            temp.push({
              label: res.description, value: res.rule_id
            })
          });
        }
        setRuleOptions(temp)
      }
    })
      .catch(error => {
        console.log(error)
      });

  }

  const getThresholds = async () => {
    accessRep.getCategory().then(response => {
      if (response.error) {
        sendNotify('error', response.error?.response?.data?.message)
      } else {
        console.log(response.data)
        let array = []
        let allVal = response.data
        allVal.forEach(td => {
          let obj = {}
          obj['name'] = td?.name
          obj['category'] = td?.category
          obj['date'] = moment(td?.createdAt).format('LLLL');
          array.push(obj)
        });
        setTableData(array)
      }
    })
      .catch(error => {
        console.log(error)

      });

  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Created date',
      dataIndex: 'date',
    }
  ];

  const OnCreate = () => {
    let payload = {};
    let name = window.localStorage.getItem('nl-user')
    let parsed = JSON.parse(name)
    if (selectedValue && clientName && buyAmount > 0
      && sellAmount > 0 && rules && rules.length
      && period
    ) {
      payload['name'] = clientName
      payload['category'] = selectedValue
      payload['rule_ids'] = rules
      payload["buys"] = buyAmount
      payload["sells"] = sellAmount
      payload["period"] = period
      accessRep.saveCategory(payload).then(response => {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', 'Created successfully')
          setSelectedValue('')
          setClientName('')
          setBuyAmount(0)
          setSellAmount(0)
          setPeriod(null)
          getThresholds()
          getRulesList()
          setRules()
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      sendNotify('error', 'Please fill all the values')
    }
  }

  return (
    <section className='mt-md-3'>
      <div className='page-card'>
        <div>
          <div className="page-header flex-md-row flex-column mb-0 mb-md-3">
            <div className='input-box mb-2 mb-md-0'>
              <label htmlFor="">Name</label> <br />
              <input type="text" onChange={(e) => setClientName(e.target.value)} placeholder='Enter client name' />
            </div>
            <div className='input-box mb-2 mb-md-0 ms-md-4'>
              <label htmlFor="">Category</label> <br />
              <input type="text" onChange={(e) => setSelectedValue(e.target.value)} placeholder='Enter category name' />
            </div>
          </div>
          <div className="page-header flex-md-row flex-column mb-0 mb-md-3">
            <div className='input-box mb-2 mb-md-0'>
              <label htmlFor="">Buys</label> <br />
              <InputNumber addonAfter="USD" className='custom-input-num' defaultValue={0}
                onChange={(e) => setBuyAmount(e)}
                value={buyAmount}
              />
            </div>
            <div className='input-box mb-2 mb-md-0'>
              <label htmlFor="">Sells</label> <br />
              <InputNumber addonAfter="USD" className='custom-input-num' defaultValue={0}
                onChange={(e) => setSellAmount(e)}
                value={sellAmount}
              />
            </div>
            <div className='input-box mb-2 mb-md-0 ms-md-4'>
              <label htmlFor="">Period</label> <br />
              <Select
                className='custom-select w-100'
                placeholder="Select a period"
                optionFilterProp="children"
                options={[{ label: 'Daily', value: 'daily' }, { label: 'Monthly', value: 'monthly' }]}
                onChange={(e) => setPeriod(e)}
                value={period}
              />
            </div>
          </div>
          <div className="page-header flex-md-row flex-column align-items-md-end">
            <div className='input-box mb-2 mb-md-0 mx-10 w-md-50'>
              <label htmlFor="">Rules</label> <br />
              <Select
                className='custom-select w-100'
                mode="multiple"
                allowClear
                placeholder="Select a rules"
                optionFilterProp="children"
                options={ruleOptions}
                value={rules}
                onChange={(e) => setRules(e)}
              />
            </div>
            <button className="primary-btn mt-2" onClick={() => OnCreate()}><i className="far fa-plus-circle"></i> <b>Create</b></button>
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} className='custom-table custom-table1 custom-scrollar' />
      </div>
    </section>
  )
}
