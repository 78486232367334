import React, { useEffect, useState, useRef } from 'react'
import { Modal, Tooltip, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { sendNotify } from '../helper/notification';
import uploadRep from '../repository/UploadRep';
import { handleDownload } from '../helper/download';

export default function Home(props) {
  const reupload = props.trackCode ? true : false;
  const user = useSelector((state) => state.auth.user)

  const uploader = useRef(null)

  const [userPhoto, setUserPhoto] = useState('asdf');
  const [document, setDocument] = useState({});
  const [loadingObj, setLoadingObj] = useState([{}]);
  const [errorObj, setErrorObj] = useState([{}]);

  const [showWebcam, setShowWebcam] = useState(false);
  const [webcamAccess, setWebcamAccess] = useState(true);

  const [trackCode, seTrackCode] = useState(props.trackCode);
  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (reupload) {
      setUserPhoto('asdf')
      setDocument({})
      setLoadingObj([{}])
      setErrorObj([{}])
    }
  }, [])


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onFileUpload = async (e) => {
    let payload = {}
    let userDetails = user?.length > 0 ? JSON.parse(user) : null;
    let file = e.target.files[0]
    if (file) {
      let excelData = await getBase64(file);
      // payload['userId'] = userDetails.id
      payload['fileName'] = file.name
      payload['fileSize'] = file.size
      payload['data'] = excelData
      setDocument(payload)
    }
  }

  const sumbitDoc = async () => {
    console.warn(document.fileName)
    if (document && document.fileName) {
      uploadRep.uploadFile(document).then(response => {
        if (response) {
          if (response.error) {
            sendNotify('error', response.error?.response?.data?.message)
          } else {
            sendNotify('success', 'Upload successfully')
            setDocument({})
          }
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      sendNotify('info', "Kinldy upload the file.")
    }
  }

  const copyTrackCode = () => {
    navigator.clipboard.writeText(trackCode)
    setIsCopied(true)
  }

  const downloadExcel = () => {
    uploadRep.downloadFile().then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          if (response && response.data) {
            const excelData = response.data;
            handleDownload(excelData, "TradeTemplate.xlsx")
          }
          sendNotify('success', 'Upload successfully')
        }
      }
    })
      .catch(error => ({ error: JSON.stringify(error) }));
  }

  return (
    <section className={`bg-style pt-m-0`}>
      <div className={`upload-card`}>
        <div className='custom-dragger p-0'>
          <div className='upload-area' onClick={() => uploader.current.click()}>
            <input type="file" accept="file/*" name="" id="" ref={uploader} onChange={onFileUpload} className='d-none' />
            <i className="fal fa-file-upload"></i>
            <h6>Click to upload file</h6>
            <p>{document.fileName}</p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <button className={`submit-btn ${submitLoading ? 'is-loading' : ''}`} onClick={sumbitDoc}>
            <span className='loading-state'><Spin /> &nbsp;&nbsp;Uploading</span>
            <span className='submit-state'><i className="far fa-cloud-upload"></i> Submit</span>
          </button>
          <button className={`submit-btn ${submitLoading ? 'is-loading' : ''}`} onClick={downloadExcel}>
            <span className='loading-state'><Spin /> &nbsp;&nbsp;Downloading</span>
            <span className='submit-state'><i className="far fa-cloud-download"></i> Get Template</span>
          </button>
        </div>
        <Modal title="Documents Tracker Code" centered width={400} className='custom-modal' open={showModal} maskClosable={isCopied} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} okButtonProps={{ disabled: !isCopied }} cancelButtonProps={{ hidden: true }} closable={isCopied}>
          <p className='content'>Copy the following code using this to track your document status on&nbsp;
            <b>"Track Submitted"</b>
            &nbsp;page</p>
          <div className="copy-box">
            <input type="text" value={trackCode} disabled={true} />
            <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
              <button onClick={copyTrackCode} className={isCopied ? 'copy-btn-active' : ''}>
                <i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i>
              </button>
            </Tooltip>
          </div>
          <p className='note-text'>*Don't forgot this code, It's unrecovered.</p>
        </Modal>
      </div>
    </section>
  )
}
