import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadRep from '../../repository/UploadRep';

//Action
export const getUploads = createAsyncThunk("getUploads", uploadRep.getUploads)
export const uploadFiles = createAsyncThunk("uploadFile", uploadRep.uploadFile)


const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: {
        isLoading: false,
        data: null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(getUploads.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(getUploads.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
        });

        builder.addCase(getUploads.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        })
        builder.addCase(uploadFiles.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(uploadFiles.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
        });

        builder.addCase(uploadFiles.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        })

    }
})

export default uploadsSlice.reducer;