import React, { useState, useEffect } from 'react'
import { Table, Tag, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { sendNotify } from '../helper/notification';

import UploadRep from '../repository/UploadRep';
import { getUploads } from '../store/uploads/uploadsSlice';
import { handleDownload } from '../helper/download';

export default function Track() {
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [excelData, setExcelData] = useState([])

  const uploads = useSelector((state) => state.uploads)

  useEffect(() => {
    if (!uploads?.data) {
      dispatch(getUploads())
    } else {
      let array = []
      uploads.data.forEach(td => {
        let obj = {}
        // obj['name'] = td.uploadBy?.name
        // obj['email'] = td.uploadBy?.email
        obj['file'] = td.fileName
        obj['date'] = moment(td.createdAt).format('LLLL')
        obj['action'] =
          <div style={{ display: 'flex', justifyContent: 'space-between', }}>
            <button key={td._id} className="primary-btn" onClick={() => run(td, true)}>
              <i className="far fa-cloud-download"></i> <b>Run</b>
            </button>
            <button className="primary-btn" onClick={() => run(td, false)}>
              <i className="far fa-file"></i> <b>View</b>
            </button>
          </div>
        array.push(obj)
      });
      setTableData(array)
    }
  }, [uploads])

  const run = (record, download) => {
    const { fileName } = record;
    let payload = fileName.split("/")[1];
    // start processing from admin end
    UploadRep.uploadFilefromAdmin(payload).then(response => {
      if (response.error) {
        sendNotify('error', response.error?.response?.data?.message)
      } else {
        if (response && response.data) {
          const { excelData, viewData } = response.data;
          if (download) {
            handleDownload(excelData, "test.xlsx")
          } else {
            let array = []
            viewData.forEach(td => {
              let obj = {}
              obj['key'] = td.key
              obj['score'] = td.score
              obj['reason'] = td.reason
              obj['risk'] =
                <div style={{ color: td.risk == "High" ? 'red' : td.risk == "Low" ? "green" : "orangered" }}>
                  {td.risk}
                </div>
              array.push(obj)
            });
            setExcelData(array)
            setShowModal(true)
          }
        }
        sendNotify('success', 'Process Complete')
      }
    })
      .catch(error => ({ error: JSON.stringify(error) }));
  }

  const columns = [
    // {
    //   title: 'Name',
    //   dataIndex: 'name',
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    // },
    {
      title: 'File name',
      dataIndex: 'file',
    },
    {
      title: 'Upload date',
      dataIndex: 'date',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    }
  ];

  const columns1 = [
    {
      title: 'Key',
      dataIndex: 'key',
    },
    {
      title: 'Score',
      dataIndex: 'score',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Risk',
      dataIndex: 'risk',
    },
  ];

  return (
    <section className='mt-md-3'>
      <div>
        <div className="page-header">
          <h1>Tracker file status</h1>
        </div>

        <Table columns={columns} dataSource={tableData} pagination={false} className='custom-table custom-table1 custom-scrollar' />
        <Modal title="Result" centered width={700} className='custom-modal' open={showModal} onOk={() => setShowModal(false)} onCancel={() => setShowModal(false)} cancelButtonProps={{ hidden: true }} >
          <Table columns={columns1} dataSource={excelData} pagination={false} className='custom-table custom-table1 custom-scrollar' />
        </Modal>
      </div>
    </section>
  )
}
