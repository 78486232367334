import { configureStore } from '@reduxjs/toolkit'
import authSlice from './auth/authSlice'
import uploadsSlice from './uploads/uploadsSlice'
import accessSlice from './uploads/accessSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    uploads: uploadsSlice,
    access: accessSlice
  },
})