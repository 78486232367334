import { createSlice } from '@reduxjs/toolkit'

import { parseJwt, validateToken } from '../../helper/parseJwt'

const initialState = {
  isLogged: false,
  authToken: '',
  userDetails: {}
}

export const authSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    checkLogged: (state) => {
      let token = localStorage.getItem('nl-paladin-auth')
      state.isLogged = token ? true : false
      state.authToken = token ? token : ''
      state.userDetails = token ? parseJwt(token) : {}
    },
  },
})

// Action creators are generated for each case reducer function
export const { checkLogged } = authSlice.actions

export default authSlice.reducer