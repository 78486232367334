// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Upload from "../pages/upload";
import Listing from "../pages/file-listing";
import Assign from "../pages/assign";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Register",
    path: "/register",
    icon: "",
    component: <Register />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  },
  {
    name: "Upload",
    path: "upload",
    icon: "fa-file-search",
    component: <Upload />,
    type: 'private',
    permissions: ['client'],
    showInMenu: true,
  },
  {
    name: "Listing",
    path: "listing",
    icon: "fa-file-search",
    component: <Listing />,
    type: 'private',
    permissions: ['admin'],
    showInMenu: true,
  },
  {
    name: "Assign",
    path: "assign",
    icon: "fa-chart-bar",
    component: <Assign />,
    type: 'private',
    permissions: ['admin'],
    showInMenu: true,
  },
  {
    name: "Error 404",
    path: "*",
    icon: "",
    component: <NotFound />,
    type: 'public',
    permissions: [],
    showInMenu: false,
  }
];

export default routes;
