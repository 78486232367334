import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <p>&#169; 2023 Northlark PTE LTD</p>
    </footer>
  )
}
