import Repository, { apiUrl } from './index';

class uploadRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getUploads() {
        let url = `${apiUrl}/upload/alluploads`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async uploadFile(payload) {
        let url = `${apiUrl}/upload/uploadtos3`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async uploadFilefromAdmin(payload) {
        let url = `${apiUrl}/upload/processFile/${payload}`;
        const reponse = await Repository.get(url, payload)
            .then(response => {
                return response;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async downloadFile() {
        let url = `${apiUrl}/upload/getExcelTemplate`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

}

export default new uploadRepository();