import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import accessRep from '../../repository/accessRep';

//Action
export const getAccess = createAsyncThunk("getAccess", accessRep.getAccess)
export const getCategory = createAsyncThunk("getCategory", accessRep.getCategory)

const accessSlice = createSlice({
    name: 'access',
    initialState: {
        isLoading: false,
        category: [],
        data: null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(getAccess.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(getAccess.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload.data;
        });

        builder.addCase(getAccess.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        })

        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.category = action.payload.data;
        });
    }
})

export default accessSlice.reducer;