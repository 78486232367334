import Repository, { apiUrl } from './index';

class rulesRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getRulesList() {
        let url = `${apiUrl}/rules/all_rules`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
}

export default new rulesRepository();