// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Outfit-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/Outfit-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../font/Outfit-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../font/Outfit-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../font/Outfit-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../font/Outfit-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Outfit-Light';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Outfit-Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: 'Outfit-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
    font-family: 'Outfit-SemiBold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
    font-family: 'Outfit-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
    font-family: 'Outfit-Black';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

:root{
    --primary: #2095f2;
    --secondary: #3d3d45;
    --background: #FAF9FF;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

body{
    font-family: 'Outfit-Regular' !important;
    background-color: var(--background) !important;
}

input:focus,
button:focus{
    outline: none !important;
}

input::placeholder,
textarea::placeholder{
    color: #bfbfbf !important;
}

a {
    text-decoration: none !important;
}

@media (min-width: 767px) {
    .w-md-50{
        width: 50% !important;
    }
}

@media (max-width: 767px) {
    .w-sm-100{
        width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/gobal.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAkC;AACtC;;AAEA;IACI,4BAA4B;IAC5B,4CAAmC;AACvC;;AAEA;IACI,6BAA6B;IAC7B,4CAAoC;AACxC;;AAEA;IACI,8BAA8B;IAC9B,4CAAqC;AACzC;;AAEA;IACI,0BAA0B;IAC1B,4CAAiC;AACrC;;AAEA;IACI,2BAA2B;IAC3B,4CAAkC;AACtC;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,wCAAwC;IACxC,8CAA8C;AAClD;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["@font-face {\n    font-family: 'Outfit-Light';\n    src: url(../font/Outfit-Light.ttf);\n}\n\n@font-face {\n    font-family: 'Outfit-Medium';\n    src: url(../font/Outfit-Medium.ttf);\n}\n\n@font-face {\n    font-family: 'Outfit-Regular';\n    src: url(../font/Outfit-Regular.ttf);\n}\n\n@font-face {\n    font-family: 'Outfit-SemiBold';\n    src: url(../font/Outfit-SemiBold.ttf);\n}\n\n@font-face {\n    font-family: 'Outfit-Bold';\n    src: url(../font/Outfit-Bold.ttf);\n}\n\n@font-face {\n    font-family: 'Outfit-Black';\n    src: url(../font/Outfit-Black.ttf);\n}\n\n:root{\n    --primary: #2095f2;\n    --secondary: #3d3d45;\n    --background: #FAF9FF;\n}\n\n*{\n    padding: 0%;\n    margin: 0%;\n    box-sizing: border-box;\n}\n\nbody{\n    font-family: 'Outfit-Regular' !important;\n    background-color: var(--background) !important;\n}\n\ninput:focus,\nbutton:focus{\n    outline: none !important;\n}\n\ninput::placeholder,\ntextarea::placeholder{\n    color: #bfbfbf !important;\n}\n\na {\n    text-decoration: none !important;\n}\n\n@media (min-width: 767px) {\n    .w-md-50{\n        width: 50% !important;\n    }\n}\n\n@media (max-width: 767px) {\n    .w-sm-100{\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
