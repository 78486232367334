import Repository, { apiUrl } from './index';

class AccessRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getAccess() {
        let url = `${apiUrl}/category/mapping`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async getCategory() {
        let url = `${apiUrl}/threshold/all_threshold`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async saveCategory(payload) {
        let url = `${apiUrl}/threshold/add_new_threshold`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

    async createAccess(payload) {
        let url = `${apiUrl}/upload/uploadtos3`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

}

export default new AccessRepository();