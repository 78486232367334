import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'
import Navbar from './Navbar'
// import Footer from './Footer'

export default function Index() {
  return (
    <div>
      <div className='base-layout'>
        <Sidebar />
        <div className='main'>
          <Navbar />
          <div className='page-content'>
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  )
}
