import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

export default function Index() {
  const { isLogged, userDetails } = useSelector((state) => state.auth)
  // console.log(isLogged);
  if (isLogged) {
    if (userDetails?.role == 'admin') {
      return <Navigate to='/app/listing' replace />;
    } else {
      return <Navigate to='/app/upload' replace />;
    }
  }

  return (
    <div className='auth-layout'>
      <div className="auth-card">
        <Outlet />
      </div>
    </div>
  )
}
