import axios from 'axios';

export const apiUrl = process.env.REACT_APP_SERVER_HOST;

let customHeaders = {
  Accept: "application/json",
};

let token = ''

customHeaders["authorization"] = `Bearer ${token}`;

export default axios.create({
  headers: customHeaders,
});